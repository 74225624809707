import { GoodsTypeText } from "@/utils/pageConfig/videovips/videoResources";

export const _deduction = {
    //筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
        {
            type: 'el-input',
            label: '奖品资源名称：',
            value: 'sourceName',
            props: {
                placeholder: '请输入',
                clearable: true
            }
        },
        {
            type: 'el-input',
            label: '备注：',
            value: 'thirdOrderRemark',
            props: {
                placeholder: '请输入',
                clearable: true
            }
        },
        {
            type: 'el-date-picker',
            label: '扣款时间：',
            value: 'beginTime',
            props: {
                type: 'date',
                'value-format': "timestamp"
            }
        },
    ],
    //列表
    table: [
        {
            prop: 'orderId',
            label: '外部订单号',
        },
        {
            prop: 'sysOrderId',
            label: '扣款订单号'
        },
        {
            prop: 'sourceName',
            label: '奖品资源名称',
        },
        {
            prop: '_type',
            label: '资源类型',
        },
        {
            prop: '_salePrice',
            label: '销售价',
        },
        {
            prop: '_purchasePrice',
            label: '渠道成本价',
        },
        {
            prop: '_statusEm',
            label: '扣款状态',
        },
        {
            prop: '_completeTime',
            label: '扣款时间',
        }
    ],
    statusEmText: [
        {
            label: '扣款成功',
            value: 'SUCCESS',
        },
        {
            label: '扣款失败',
            value: 'FAIL',
        },
        {
            label: '扣款中',
            value: 'DOING',
        }
    ]
};
