
import { Component, Vue } from "vue-property-decorator";
import { _deduction } from "@/utils/pageConfig/videovips/_deduction";
import { Foundation } from "@/utils/Foundation";
import recharge from "@/views/videovips/components/recharge.vue";
import refund from "@/views/videovips/components/refund.vue";
import Api_videoApi from "@/utils/API/videovip/videoApi";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import { SourcetypeText } from "@/utils/pageConfig/videovips/videoResources";

@Component({
    components: { recharge, refund },
})
export default class FinanceManage extends Vue {
    /*表格相关*/
    pageConfig: any = _deduction;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
        scenes: "RESOURCE",
    };
    showTable: boolean = false;
    exportForm: any = {
        type: {
            tag: "RESOURCE_PLATFORM_RECORD",
            url: "core/api/v1/admin/c/export/exec/resource/platform/record/admin",
        },
        fileName: "扣款明细",
    };

    mounted() {
        this.getAllMerchant();
    }

    async getAllMerchant() {
        let options: any = [];
        let res: any = await Api_videoApi.getOpenresource();
        res.map((e: any) => {
            options.push({ label: e.coName, value: e.id });
        });

        this.pageConfig.search[0].options = options;
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await Api_videoApi.resourcePage(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._completeTime = Foundation.dateFormat(e.completeTime);
                    e._salePrice = Foundation.toYUAN(e.salePrice);
                    e._purchasePrice = Foundation.toYUAN(e.purchasePrice);
                    e._totalRecharge = Foundation.toYUAN(e.totalRecharge);
                    e._totalRefund = Foundation.toYUAN(e.totalRefund);
                    e._type = Foundation.findInArray(
                        SourcetypeText,
                        "value",
                        e.type
                    ).label;
                    e._statusEm = Foundation.findInArray(
                        this.pageConfig.statusEmText,
                        "value",
                        e.statusEm
                    ).label;
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        query = Foundation.resetDate(query);
        this.exportForm.condition = query;
        if (!query.merchantId) {
            this.$message.error("请先选择商户！");
            return;
        }
        this.showTable = true;
        this.params = {
            current: 1,
            size: 20,
            ...query,
        };
        this.getTableData();
    }

    /*冻结*/
    handleFreeze() {
        Foundation.beforeDelete(
            () => {
                /*API_StoreApi.bindingUp(row.id).then((res) => {
                    this.$message.success("解绑成功");
                    this.getTableData();
                });*/
            },
            "确认要冻结此项吗",
            "操作取消"
        );
    }

    /*充值*/
    handleRecharge() {
        (this.$refs.recharge as any).show();
    }

    /*退款*/
    handleRefund() {
        (this.$refs.refund as any).show();
    }

    /*导出*/
    handleExport() {
        (this.$refs.exports as any).show(this.exportForm);
    }
}
